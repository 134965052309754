import React, { useRef, useState } from "react"

function FAQ(questions) {
  const [expandedId, setExpandedId] = useState(null)
  const contentRefs = useRef({})

  const toggleItem = id => {
    setExpandedId(prevId => (prevId === id ? null : id))
    if (expandedId !== id && contentRefs.current[id]) {
      contentRefs.current[id].focus()
    }
  }

  return (
    <>
      <h2 className="androidhead h4">
        <strong>FAQs:</strong>
      </h2>
      <div className="border rounded gap-1 drop accordion">
        {questions.questions.map((ans, index) => (
          <div
            className={`group ${
              expandedId === ans.id ? "details-open" : ""
            } border accordion-item`}
            key={`${ans.key}_${index}`}
          >
            <h3 className="accordion-header m-0 d-flex align-items-center" id={`heading-${ans.id}`}>
              <button
                type="button"
                className={`accordion-button text-left ${
                  expandedId === ans.id ? "dashed-border" : ""
                } py-2 m-0 w-100`}
                aria-expanded={expandedId === ans.id}
                aria-controls={`content-${ans.id}`}
                onClick={() => toggleItem(ans.id)}
              >
                {expandedId === ans.id ? (
                  <i className="icofont-caret-down" aria-hidden={true}></i>
                ) : (
                  <i className="icofont-caret-right" aria-hidden={true}></i>
                )}
                <span> &nbsp; {ans.question} </span>
              </button>
            </h3>
            <div
              id={`content-${ans.id}`}
              role="region"
              aria-labelledby={`heading-${ans.id}`}
              className="text-neutral-600 my-0 py-2 group-open:animate-fadeIn pl-2 pr-2 bg-light"
              hidden={expandedId === ans.id ? null : "hidden"}
              ref={ref => (contentRefs.current[ans.id] = ref)}
            >
              {ans.answer}

              {ans.points?.length > 0 &&
              ans.points.map((point, index) => (
                <div key={index} className="border pl-2 pr-2 bg-light pt-1 mt-2">
                  {point.pointsId && (
                    <h4 className="h6 font-weight-bold mt-2">
                      {point.pointsId}. &nbsp;{point.pointsQuestion}
                    </h4>
                  )}

                  <ul className="">
                    {point.pointAnswer && (
                      <>
                        {point.pointAnswer && (
                          <li className="list-unstyled">{point.pointAnswer}</li>
                        )}
                        {point.pointAnswers && (
                          <li className="list-unstyled">{point.pointAnswers}</li>
                        )}
                      </>
                    )}

                    {point?.pointAnswer1 && (
                      <>
                        <li>{point.pointAnswer1}</li>
                        <li>{point.pointAnswer2}</li>
                        <li>{point.pointAnswer3}</li>
                        <li>{point.pointAnswer4}</li>
                        <li>{point.pointAnswer5}</li>
                        <li>{point.pointAnswer6}</li>
                      </>
                    )}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
export default FAQ
