import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import mobile from "../assets/img/Mobile-login.svg"
// import secure from "../assets/img/Secure-login.svg"
import FAQ from "../../utils"
import questions from "../../penntestFaq.json"
import "./Testing-styles/penetration.css"

function PenetrationTesting() {
  return (
    <Layout>
      <SEO
        title="Penetration Testing Company in Delhi NCR, India, USA & UK"
        description="Penetration Testing is one of the core services offered by D2i. D2i is one of the leading company in India specializing in Penetration Testing."
      />

      <div className="penetration-testing">
        <div className="pen-first-div">
          <div className="android-heading">
            <h1>Penetration Testing Services</h1>
          </div>

          <p>
            A penetration test involves manual and semi-automated tests tailored
            to your technical and functional architecture. Unlike scanners,
            penetration testing uncovers hidden flaws, including logical
            vulnerabilities. It goes beyond detection, conducting a deeper
            analysis by exploiting identified flaws to assess their impact. Our
            expert team specializes in Penetration Testing services help
            organizations identify and address security vulnerabilities in their
            networks, applications, and systems.
          </p>

          <h2 className="androidhead h3 mb-3">What We Offer:</h2>
          <ul className="mb-4">
            <li>External and Internal Network Penetration Testing</li>
            <li>Web Application Penetration Testing</li>
            <li>Mobile Application Penetration Testing</li>
            <li>Wireless Network Penetration Testing</li>
            <li>Physical Security Assessments</li>
            <li>Social Engineering Testing</li>
          </ul>
          <p>
            Our team of certified ethical hackers utilizes industry-leading
            tools and methodologies to simulate real-world attacks and provide
            actionable recommendations for improving security defenses.
          </p>

          <h2 className="androidhead h3 mb-3">Why Choose Us:</h2>
          <ul className="FAQ">
            <li>
              <strong>Expertise:</strong> Our team consists of skilled
              professionals with extensive experience in cybersecurity and
              penetration testing.
            </li>
            <li>
              <strong>Comprehensive Approach:</strong> We conduct thorough
              assessments covering various attack vectors to ensure
              comprehensive security coverage.
            </li>
            <li>
              <strong>Customized Solutions:</strong> We tailor our penetration
              testing services to meet your organization's specific needs and
              industry requirements.
            </li>
            <li>
              <strong>Transparent Reporting:</strong> We provide detailed
              reports outlining identified vulnerabilities, potential impacts,
              and recommended remediation steps.
            </li>
            <li>
              <strong>Continuous Support:</strong> We offer ongoing support and
              guidance to help you implement security improvements and
              strengthen your defenses.
            </li>
          </ul>

          <h2 className="androidhead h3 mb-3">Our Process:</h2>
          <ol className="FAQ">
            <li>
              <strong>Initial Consultation:</strong> We begin with a detailed
              discussion to understand your organization's objectives,
              infrastructure, and security requirements.
            </li>
            <li>
              <strong>Scope Definition:</strong> Based on the consultation, we
              define the scope of the penetration testing engagement, including
              target systems, applications, and testing methodologies.
            </li>
            <li>
              <strong>Testing Execution:</strong> Our team conducts penetration
              testing activities using a combination of automated tools and
              manual techniques to identify vulnerabilities and assess security
              controls.
            </li>
            <li>
              <strong>Analysis and Reporting:</strong> We analyze the findings
              from the penetration testing activities and prepare a
              comprehensive report detailing discovered vulnerabilities, their
              severity, and recommended remediation steps.
            </li>
            <li>
              <strong>Remediation Assistance:</strong> We provide assistance and
              guidance to help your organization address identified
              vulnerabilities and improve overall security posture.
            </li>
            <li>
              <strong>Continuous Monitoring:</strong> We offer ongoing
              monitoring and support to ensure that security vulnerabilities are
              addressed promptly and that your organization remains resilient
              against evolving threats.
            </li>
          </ol>

          <h2 className="androidhead h3 mb-3">Tools We Use:</h2>
          <ul className="mb-4">
            <li>Nmap</li>
            <li>Metasploit</li>
            <li>Burp Suite</li>
            <li>OWASP ZAP</li>
            <li>Wireshark</li>
            <li>SQLMap</li>
            <li>Acunetix</li>
            <li>Nessus</li>
            <li>OpenVAS</li>
            <li>Hashcat</li>
          </ul>
          <p>
            Protect your organization against cyber threats with our Penetration
            Testing Services. Contact us today to schedule your assessment and
            take proactive steps towards enhancing your security defenses.
          </p>
        </div>
        <div className="FAQ">{FAQ(questions)}</div>
      </div>
    </Layout>
  )
}

export default PenetrationTesting
